<template>
  <div class="push-list">
    <div class="table-responsive thin-scrollbar" v-if="!state.push.loaded || state.push.list.length" :class="{skeleton: !state.push.loaded}">
      <table class="table">
        <thead>
        <tr>
          <td>
            <span>제목</span>
          </td>
          <td>
            <span>내용</span>
          </td>
          <td>
            <span>상태</span>
          </td>
          <td>
            <span>발송일</span>
          </td>
          <td>
            <span>발송시간</span>
          </td>
          <td>
            <span>생성일</span>
          </td>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(i, idx) in state.push.list" :key="idx">
          <td>
            <a class="color-anchor pointer" @click="edit(i)">{{ i.title }}</a>
          </td>
          <td class="max-width">
            <span>{{ i.content }}</span>
          </td>
          <td>
            <span>{{ i.status }}</span>
          </td>
          <td>
            <span>{{ i.sendDate }}</span>
          </td>
          <td>
            <span>{{ i.sendTime }}</span>
          </td>
          <td>
            <span>{{ i.createDate }}</span>
          </td>
        </tr>
        </tbody>
      </table>
      <PaginationJpa className="btn-primary" :change="load" :total="state.total" :pageable="state.pageable" v-if="state.pageable.size"/>
    </div>
    <NoData v-else/>
    <a class="add" @click="add()">
      <i class="fa fa-plus"></i>
    </a>
  </div>
</template>

<script>
import mixin from "@/scripts/mixin";
import {defineComponent, reactive} from "@vue/composition-api";
import Anchor from "@/components/Anchor";
import http from "@/scripts/http";
import PaginationJpa from "@/components/PaginationJpa";
import NoData from "@/components/NoData";
import store from "@/scripts/store";

function Component(initialize) {
  this.name = "pageAdminPushList";
  this.initialize = initialize;
}

export default defineComponent({
  components: {NoData, PaginationJpa, Anchor},
  mixins: [mixin],
  setup() {
    const component = new Component(() => {
      load();
    });

    const state = reactive({
      push: {
        loaded: false,
        list: [],
      },
      args: {
        page: 0,
        size: 10,
      },
      pageable: {
        page: 0,
        size: 0,
      },
      total: 0,
    });

    const load = (page) => {
      state.push.loaded = false;
      state.args.page = page || 0;

      for (let i = 0; i < 5; i += 1) {
        state.push.list.push({
          title: "Wait a moment",
          content: "Please wait a moment",
          // memberName: "Wait",
          // updateDate: "0000-00-00 00:00:00"
        });
      }

      http.get("/api/admin/push", state.args).then(({data}) => {
        state.push.loaded = true;
        state.push.list = data.body.content;
        state.pageable = data.body.pageable;
        state.total = data.body.total;
      });
    };

    const add = () => {
      store.commit("openModal", {
        name: "modalPush",
        params: {
          type: "add",
        },
        callback: `${component.name}.load`
      });
    };

    const edit = (push) => {
      store.commit("openModal", {
        name: "modalPush",
        params: {
          push,
          type: "edit",
        },
        callback: `${component.name}.load`
      });
    };

    return {component, state, load, add, edit};
  }
});
</script>

<style lang="scss" scoped>
.push-list {
  .table-responsive {
    table {
      thead {
        background-color: $colorBackground;
      }

      tbody {
        tr {
          transition: 0.18s;

          td {
            a {
              &:hover span {
                text-decoration: underline;
              }
            }

            span {
              vertical-align: middle;

              &.dot {
                border-radius: 50%;
                display: inline-block;
                width: $px5;
                height: $px5;
                vertical-align: middle;
                margin-right: $px5;

                &.pending {
                  background-color: #f5365c;
                }
              }
            }

            &.max-width {
              max-width: $px200;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }

          &:hover {
            background-color: $colorBackground;
          }
        }
      }
    }

    .pagination {
      margin-top: $px30;
    }

    &::-webkit-scrollbar {
      height: $px3;
    }

    &.skeleton {
      table {
        thead tr, tbody tr {
          td, th {
            text-overflow: initial;

            span {
              @include skeleton;
            }
          }
        }
      }
    }
  }

  .no-data {
    margin: $px50 0;
  }

  .add {
    background: $colorPurple;
    bottom: $px30;
    border-radius: 50%;
    display: inline-block;
    height: $px60;
    padding: 0;
    left: 100%;
    width: $px60;
    cursor: pointer;
    text-align: center;
    position: sticky;
    transition: background-color 0.18s;
    z-index: 110;

    .fa-plus {
      color: #fff;
      font-size: $px22;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &:hover {
      background: $colorPurpleActive;
    }
  }
}
</style>